/*.dx-tabs-wrapper {
    display: contents !important
}*/

.trip-items {
    padding: 20px;
    cursor: pointer;
}

    .trip-items:hover {
        background: #ececec;
        box-shadow: 0 8px 15px rgba(0,0,0,0.3);
    }

.gantt-icon-buttons {
    border: none !important;
    height: 16.5px !important;
}

.gantt-icon-buttons .dx-button-content {
    padding: 0px !important;
}

div.specialForm .dx-field-item-label-text {
    white-space: normal
}

.dx-field-item-label-content {
    max-width: 280px
}

.lineThroughText {
    text-decoration: line-through;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.disable-date {
    background-image: repeating-linear-gradient(135deg, rgba(244, 67, 54, 0.1), rgba(244, 67, 54, 0.1) 4px, transparent 4px, transparent 9px);
    color: #9B6467;
}

.lockedPeriod {
    background-color: darkgray !important;
    opacity: .45 !important;
}

@media (min-width: 1200px) {
    .container {
        width: 1920px !important;
    }
}

.container.wide {
    width: 1920px;
}

.Modal {
    position: absolute;
    top: 25%;
    left: 25%;
    right: 25%;
    bottom: 25%;
    background-color: white;
    border: solid;
    border-width: 1px;
    padding: 5px;
}

.Modal.Large {
    position: absolute;
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 15%;
    background-color: white;
    border: solid;
    border-width: 1px;
    padding: 5px;
}

.selected-row {
    background-color: #009688 !important;
}

.whateverClassYouWish {
    width: 250px;
    height: 250px;
}

.chart-inner-text {
    position: absolute;
    top: 0;
    right: 0;
    left: -620px;
    bottom: 0;
    padding-top: 8%;
    text-align: center;
    font-size: 13px;
}

.chart-inner-value {
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c596d;
    font-size: 15px;
    padding-bottom: 5px;
}

.donutchart-track {
    fill: transparent;
    stroke: #DAE2E5;
    stroke-width: 26;
}

.donutchart-indicator {
    fill: transparent;
    stroke: #009688;
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
}

.donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}


.donutchart-text {
    font-family: 'Roboto';
    fill: #607580;
}

.donutchart-text-val {
    font-size: 22px;
}

.donutchart-text-percent {
    font-size: 14px;
}

.donutchart-text-label {
    font-size: 9px;
}

.dx-gantt .dx-gantt-tm.current-time {
    border-left: 1px solid red;
}

.transportLeaderRow {
    background-color: #60a69f;
    color: white !important;
}

.transportLeaderRow .dx-link {
    background-color: #60a69f;
    color: white !important;
}

.cancelledRow {
    background-color: #b3b3b3;
    color: white !important;
}

.cancelledRow .dx-link {
    background-color: #b3b3b3;
    color: white !important;
}

.summaryRow {
    overflow: visible;
}

/* KANBAN */
#kanban {
    white-space: nowrap;
}

.list {
    border-radius: 8px;
    margin: 5px;
    background-color: rgba(192, 192, 192, 0.4);
    display: inline-block;
    vertical-align: top;
    white-space: normal;
}

.list-title {
    font-size: 16px;
    padding: 10px;
    padding-left: 30px;
    margin-bottom: -10px;
    font-weight: bold;
    cursor: pointer;
}

.scrollable-list {
    height: 600px;
    width: 260px;
}

.sortable-cards {
    min-height: 380px;
}

.card {
    position: relative;
    background-color: white;
    box-sizing: border-box;
    width: 230px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
}

.card-subject {
    padding-bottom: 10px;
}

.card-description {
    padding-bottom: 10px;
    font-weight: bold;
}

.card-assignee {
    opacity: 0.6;
}

.card-actions {
    display: flex;
    justify-content: space-between;
}

.card-priority {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 5px;
    width: 5px;
    border-radius: 2px;
    background: #86c285;
}

.priority-1 {
    background: #adadad;
}

.priority-2 {
    background: #86c285;
}

.priority-3 {
    background: #edc578;
}

.priority-4 {
    background: #ef7d59;
}

.square-icon i[class^="bi-"], .square-icon i[class*=" bi-"] {
    line-height: 1em !important;
}